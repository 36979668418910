@import 'assets/variables';
@import 'assets/mixins';

.ParagraphHighlightedEvent {
  display: grid;
  grid-template-columns: 2304fr 1536fr;
  grid-template-rows: 1288fr 672fr;
  place-items: center;
  height: 100vh;
  padding-bottom: px-to-vw(200);

  &__header {
    display: flex;
    grid-column-start: 2;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-inline: px-to-vw(200);
    background-color: $color--primary;
  }

  &__heading.asH2 {
    margin-top: px-to-vw(60);
    margin-bottom: px-to-vw(80);
    font-weight: 500;
  }

  &__image {
    grid-row: span 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__summary {
    grid-column-start: 2;
    padding-inline: px-to-vw(200);
  }

  &__summary-text {
    @include body--small;

    margin: 0;
  }
}
