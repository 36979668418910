@import 'assets/mixins';

.MetaData {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  &--row {
    display: flex;
    flex-direction: column;
    gap: px-to-vw(40);
  }

  li {
    display: flex;
    align-items: center;

    &:before {
      margin-right: px-to-vw(40);
      font-size: px-to-vw(96);
    }

    &:not(:last-child) {
      margin-right: px-to-vw(120);
    }
  }
}
