@import 'assets/variables';
@import 'assets/mixins';

body {
  @include font-size($font-size--body);
  @include font--primary;

  font-weight: normal;
  line-height: px-to-vw($line-height--body);
  color: $color--black;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $color--grey;
  text-decoration: underline;

  &:hover {
    color: $color--black;
  }

  &:focus {
    color: $color--primary;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
  color: $color--black;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

h1,
.asH1 {
  @include h1;
}

h2,
.asH2 {
  @include h2;
}

h3,
.asH3 {
  @include h3;
}

.text-small {
  @include body--small;
}
