@import 'assets/variables';

*, *:before, *:after {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: px-to-vw(10);
}
