@use 'sass:map';

// -----------------------------------------------------------------------------
//
// Grid utilities
//
// -----------------------------------------------------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
);

$grid-gutter-width-base: 30px;
$grid-xs-gutter-width-base: 30px;
$grid-sm-gutter-width-base: 30px;
$grid-md-gutter-width-base: 30px;
$grid-lg-gutter-width-base: 30px;
$grid-xl-gutter-width-base: 30px;

$grid-gutter-widths: (
  xs: $grid-xs-gutter-width-base,
  sm: $grid-sm-gutter-width-base,
  md: $grid-md-gutter-width-base,
  lg: $grid-lg-gutter-width-base,
  xl: $grid-xl-gutter-width-base,
);

$screen-sm: #{map.get($grid-breakpoints, sm)};
$screen-md: #{map.get($grid-breakpoints, md)};
$screen-lg: #{map.get($grid-breakpoints, lg)};
$screen-xl: #{map.get($grid-breakpoints, xl)};

$screen-phone: $screen-sm;
$screen-tablet: $screen-md;
$screen-desktop: $screen-lg;
$screen-lg-desktop: $screen-xl;

$screen-xs-max: #{map.get($grid-breakpoints, sm) - 1};
$screen-sm-max: #{map.get($grid-breakpoints, md) - 1};
$screen-md-max: #{map.get($grid-breakpoints, lg) - 1};
$screen-lg-max: #{map.get($grid-breakpoints, xl) - 1};

// Lazy media query variables.
$mobile: 'screen and (min-width: #{$screen-sm})';
$tablet: 'screen and (min-width: #{$screen-md})';
$normal: 'screen and (min-width: #{$screen-lg})';
$wide: 'screen and (min-width: #{$screen-xl})';
$mobile-only: 'screen and (max-width: #{$screen-sm-max})';
$tablet-only: 'screen and (min-width: #{$screen-md}) and (max-width: #{$screen-md-max})';
$normal-only: 'screen and (min-width: #{$screen-lg}) and (max-width: #{$screen-lg-max})';
$mobile-down: 'screen and (max-width: #{$screen-sm-max})';
$tablet-down: 'screen and (max-width: #{$screen-md-max})';
$normal-down: 'screen and (max-width: #{$screen-lg-max})';

// -----------------------------------------------------------------------------
//
// Spacing
//
// -----------------------------------------------------------------------------

$spacers: ();
$spacers: map.merge(
  (
    0: 0,
    1: 8px,
    2: 16px,
    3: 24px,
    4: 32px,
    5: 40px,
    6: 48px,
    7: 56px,
    8: 64px,
    9: 72px,
  ),
  $spacers
);

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

// Primary fonts will be used for 90% of the text styles, mainly body text.
// Secondary fonts will be used mainly for headings.
$font--primary: 'Omnes Pro', 'omnes-pro', helvetica, arial, sans-serif;
$font--secondary: 'Omnes Pro', 'omnes-pro', helvetica, arial, sans-serif;
$font--tertiary: 'Omnes Pro', 'omnes-pro', helvetica, arial, sans-serif;

// MOBILE: Font sizes (in pixels) / line-heights
$font-size--h1: 200;
$line-height--h1: 240;

$font-size--h2: 130;
$line-height--h2: 156;

$font-size--h3: 100;
$line-height--h3: 150;

$font-size--body: 100;
$line-height--body: 150;

$font-size--body--small: 70;
$line-height--body--small: 112;


// -----------------------------------------------------------------------------
//
// Colors
//
// -----------------------------------------------------------------------------

$color--minus-1: #F0F0F0;
$color--primary: #F0F0EC;
$color--plus-1: #D9D9CF;
$color--plus-2: #C2C2B2;
$color--plus-3: #ACAC96;
$color--plus-4: #959579;

$color--black: #000;
$color--white: #FFF;
$color--grey: #F0F0F0;
$color--green: #A6D8C1;
$color--primary: #F6F6F4;

$color--green--hover: #4BAB7F;

$color--disabled: #D8D7E2;
$color--text: $color--black;

$color--transparent: rgb(255 255 255 / 0%);
$color--placeholder: #707070;

$color--notification: #4A9B66;
$color--warning: #F8E49D;
$color--error: #EC3E33;

// -----------------------------------------------------------------------------
//
// Borders
//
// -----------------------------------------------------------------------------

$color--border: #E6E9F1;
$border-radius--primary: 4px;
$border-radius--secondary: 8px;

// -----------------------------------------------------------------------------
//
// Shadows
//
// -----------------------------------------------------------------------------
$box-shadow--primary: 0 8px 10px 0 rgb(77 73 255 / 8%);
$box-shadow--secondary: 0 30px 30px 0 rgb(77 73 255 / 8%);
$box-shadow--tertiary: 0 4px 5px 0 rgb(77 73 255 / 16%);

// -----------------------------------------------------------------------------
//
// z-index
//
// -----------------------------------------------------------------------------
$z-index-0: 0;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 100;
$z-index-5: 1000;

// -----------------------------------------------------------------------------
//
// Animation
//
// -----------------------------------------------------------------------------
$animation-timing--cubic-bezier: cubic-bezier(0.23, 0, 0.03, 1.01);

// -----------------------------------------------------------------------------
//
// Icons
//
// -----------------------------------------------------------------------------

// Define the amount of rows.
$icon-rows: 3;

// Define the icons from left to right in the icongrid using a human-readable
// name. This will also be part of the classname.
$icon-names: (
  lang-de,
  lang-en,
  lang-it,
  lang-fr,
  lang-nl,
  lang-es,
  hamburger,
  search,
  ticket,
  close,
  chevron-left,
  chevron-down,
  clock,
  pin,
  calendar,
  box,
  checkmark,
  plus,
  send,
  home,
  euro,
  link,
  facebook,
  twitter,
  youtube,
  linkedin,
  instagram,
  hourglass,
  family,
);
