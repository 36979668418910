@import 'assets/mixins';

@import '../../styles/assets/variables';

.Tag {
  display: inline-block;
  padding: 0 px-to-vw(40);
  color: $color--white;
  background: $color--black;
}
