@import 'assets/mixins';

.ViewEventsList {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding-top: px-to-vw(150);
  padding-bottom: px-to-vw(300);
  padding-inline: px-to-vw(200);

  &__header {
    position: fixed;
    z-index: $z-index-1;
    top: 0;
    display: block;
    width: 100%;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: px-to-vw(250);
      background: linear-gradient(180deg, rgb(255 255 255 / 100%) 0%, rgb(255 255 255 / 100%) 35%, rgb(0 212 255 / 0%) 100%);
    }

    h1 {
      padding-top: px-to-vw(150);
      font-weight: 500;
      background-color: $color--white;
    }
  }

  &__items {
    padding-top: px-to-vw(300);
  }

  &__row {
    display: grid;
    grid-template-columns: px-to-vw(400) px-to-vw(1465) auto auto;
    gap: px-to-vw(100);

    + .ViewEventsList__row {
      border-top: 1px solid $color--plus-1;
    }
  }
}
