@import 'assets/variables';

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

@mixin font--primary($weight: normal, $style: normal) {
  font-family: $font--primary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--secondary($weight: 600, $style: normal) {
  font-family: $font--secondary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--tertiary($weight: normal, $style: normal) {
  font-family: $font--tertiary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font-size($size) {
  font-size: px-to-vw(($size));
}

@mixin h1 {
  @include font--secondary;

  font-size: px-to-vw($font-size--h1);
  font-weight: 500;
  line-height: px-to-vw($line-height--h1);

  span {
    display: block;
    color: $color--plus-4;
  }
}

@mixin h2 {
  @include font--secondary;

  font-size: px-to-vw($font-size--h2);
  font-weight: 600;
  font-weight: normal;
  line-height: px-to-vw($line-height--h2);
}

@mixin h3 {
  @include font--secondary;

  font-size: px-to-vw($font-size--h3);
  font-weight: 500;
  line-height: px-to-vw($line-height--h3);
}

// Body mixins

@mixin body--regular {
  @include font--primary;

  font-size: px-to-vw($font-size--body);
  line-height: px-to-vw($line-height--body);
}

@mixin body--small {
  @include font--primary;

  font-size: px-to-vw($font-size--body--small);
  line-height: px-to-vw($line-height--body--small);
}

@function px-to-vw($px) {
  @return #{$px * 0.026}vw;
}
