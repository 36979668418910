@import 'assets/variables';
@import 'assets/mixins';

.Footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: px-to-vw(200);
  padding-inline: px-to-vw(200);
  font-size: px-to-vw(70);
  color: $color--white;
  background: $color--black;
}

.Footer__progress-bar {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: px-to-vw(25);
}

.Footer__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-block: px-to-vw(44);
}

.Footer__logo {
  width: px-to-vw(1175);
}

.Footer p {
  margin: 0;
  line-height: 1;
}
