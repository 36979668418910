@import 'assets/variables';
@import 'assets/mixins';

#time-indicator {
  position: fixed;
  z-index: $z-index-5;
  right: px-to-vw(100);
  bottom: px-to-vw(100);
  width: px-to-vw(100);
}
