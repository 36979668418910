@import 'assets/mixins';
@import 'assets/variables';

.ProgressBar__item {
  overflow: hidden;
  flex: 1 1 auto;
  width: 100%;
  height: px-to-vw(7);
  background: rgb(255 255 255 / 30%);
  border-radius: px-to-vw(10);

  &__filler {
    width: 100%;
    height: px-to-vw(7);
    background: $color--white;

    .ProgressBar__item--waiting & {
      transform: translate3d(-101%, 0, 0);
    }

    .ProgressBar__item--passed & {
      transform: translate3d(0, 0, 0);
    }

    .ProgressBar__item--active & {
      animation-name: progress-bar;
      animation-timing-function: linear;
    }
  }
}

@keyframes progress-bar {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
