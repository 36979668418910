@import 'assets/mixins';

.Event {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: 400fr 1465fr 877fr 750fr;
  gap: px-to-vw(100);
  align-items: center;
  padding-block: px-to-vw(50);

  &__image {
    aspect-ratio: 2 / 1;
    width: 100%;
    object-fit: cover;
  }

  h2 {
    font-weight: normal;
  }

  .Tag {
    justify-self: end;
  }
}
