@import 'assets/variables';
@import 'assets/mixins';

.ParagraphSlideBasic {
  display: grid;
  grid-template-columns: 1536fr 2304fr;
  width: 100%;
  padding-bottom: px-to-vw(200);

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__table {
    & td {
      @include body--small;

      line-height: 1.25;
    }

    & .Table__row--amount-of-items-3 {
      display: grid;
      grid-template-columns: 300fr 200fr 300fr;
    }

    & .Table__row--amount-of-items-2 {
      display: grid;
      grid-template-columns: 500fr 300fr;
    }

    &+.ParagraphSlideBasic__table {
      margin-top: px-to-vw(100);
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - #{px-to-vw(200)});
    padding-block: px-to-vw(100);
    padding-inline: px-to-vw(250);
  }

  .Table__caption-container {
    display: grid;
    grid-template-columns: 500fr 300fr;
    align-items: center;
  }

  & .Table__subcaption {
    @include body--small;

    font-weight: 500;

    &>div>span:last-child {
      color: $color--plus-4;
    }
  }
}
