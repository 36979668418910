@import 'assets/mixins';
@import 'assets/grid';

.App {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}
