@import 'assets/variables';
@import 'assets/mixins';

.Error {

  position: absolute;
  width: 100%;
  height: 100%;

  color: $color--black;
  background-image: url('../../assets/images/backgrounds/error_background_image.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: px-to-vw(2260);
    height: px-to-vw(1156);
    text-align: center;

    background-color: $color--white;

    &__logo {

      width: px-to-vw(400);
      height: px-to-vw(400);
      margin: px-to-vw(250) auto px-to-vw(100);
      background-color: $color--primary;

      img {
        transform: translateY(-50%);
        width: px-to-vw(218);
        margin-top: 50%;
      }
    }
  }
}
